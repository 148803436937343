<!-- @format -->

<template>
	<layout-wide>
		<sub-navbar page="Accounting" />
		<!--    <b-button-->
		<!--      v-if="showSetCreditExchange"-->
		<!--      class="is-success"-->
		<!--      @click="setValues"-->
		<!--    >-->
		<!--      Set Credit Exchange</b-button-->
		<!--    ><br />-->
		<section class="margin-top-20">
			<div class="block">
				<b-radio v-model="radio" name="name" native-value="%collect%">
					Inbetalningar
				</b-radio>
				<b-radio v-model="radio" name="name" native-value="%set off%">
					Övriga utjämningar
				</b-radio>
			</div>
		</section>
		<section>
			<b-field class="margin-top-20" label="Select beginning date">
				<b-datepicker
					v-model="reportData.beginningDate"
					:show-week-number="showWeekNumber"
					:locale="locale"
					:first-day-of-week="firstDayOfWeek"
					placeholder="Click to select..."
					icon="calendar-today"
					trap-focus
				>
				</b-datepicker>
			</b-field>
			<b-field label="Select ending date">
				<b-datepicker
					v-model="reportData.endingDate"
					:show-week-number="showWeekNumber"
					:locale="locale"
					:first-day-of-week="firstDayOfWeek"
					placeholder="Click to select..."
					icon="calendar-today"
					trap-focus
				>
				</b-datepicker>
			</b-field>
			<b-button class="is-primary margin-top-20" type="submit" @click="getData"
				>Get Results</b-button
			>
			<b-table
				:data="listArr"
				:columns="columns"
				class="smallFonts tale-style"
			></b-table>
		</section>
		<div>
			<b-button class="is-info margin-top-20" @click="bookKeepPayments">
				Book Keep Collected Payments</b-button
			>
		</div>
	</layout-wide>
</template>

<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import GetCollectedPaymentsQuery from './graphql/queries/getCollectedPayments.query.gql'
import BookKeepPaymentMutation from './graphql/mutations/bookKeepPayment.mutation.gql'
import SetPaymentBookKeptTrueMutation from './graphql/mutations/setPaymentBookKeptTrue.mutation.gql'
import Store from '@/store'
import storeAccounting from '@/_srcv2/pages/accounting/accounting-store/storeAccounting.js'
import {
	computed,
	getCurrentInstance,
	reactive,
	ref,
} from '@vue/composition-api'
import { useMutation, useQuery } from '@vue/apollo-composable'
import dateformat from 'dateformat'
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber'
//  ----------------
// import SetPaymentCreditInExchangeMutation from '@/_srcv2/pages/accounting/page-get-collected-payments/graphql/mutations/setPaymentCreditInExchange.mutation.gql'
// import GetCollectedPaymentsFromPaymentsTableQuery from '@/_srcv2/pages/accounting/page-get-collected-payments/graphql/queries/getCollectedPaymentsFromPaymentsTable.query.gql'
import Router from '@/router'
//   ------------------
export default {
	name: 'GetCollectedPayments',
	components: {
		SubNavbar,
	},
	setup() {
		const showWeekNumber = false
		const firstDayOfWeek = 1
		const locale = 'sv-SE'
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const radio = ref('%collect%')
		const beginningDateVariable = ref('')
		const endingDateVariable = ref('')
		const fetchVariableDates = (bd, ed) => {
			return new Promise((resolve, reject) => {
				if ((bd && ed) !== null || undefined) {
					beginningDateVariable.value = dateformat(
						reportData.beginningDate,
						'yyyy-mm-dd',
					)
					endingDateVariable.value = dateformat(
						reportData.endingDate,
						'yyyy-mm-dd',
					)
					reportHeaders.reportDate = dateformat(
						reportData.endingDate,
						'yyyy-mm-dd',
					)
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const { refetch } = useQuery(
			GetCollectedPaymentsQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
				bd: beginningDateVariable.value,
				ed: endingDateVariable.value,
				payment_type: radio.value,
			}),
			options,
		)
		const getData = () => {
			fetchVariableDates()
				.then(() => refetch())
				.then((result) => getTableValues(result))
		}
		const { getPaymentAbbreviation, reportData, reportHeaders } =
			storeAccounting()
		const tableValues = ref([])
		const getTableValues = (qResult) => {
			tableValues.value = qResult.data.payments.map((item) => {
				console.log('>>>>>> item', item)
				const accounts = reactive({
					debitAccount: '',
					debitAmount: 0,
					debitExchangeAmount: 0,
					creditAccount: '',
					creditAmount: 0,
					creditExchangeAmount: 0,
				})
				const isPaymentCreditPositive = item.payment_credit > 0
				switch (isPaymentCreditPositive) {
					case true:
						accounts.debitAccount = item.payment_debit_account
						accounts.debitAmount = item.payment_credit
						accounts.debitExchangeAmount = item.payment_credit_in_exchange
						accounts.creditAccount = item.payment_credit_account
						accounts.creditAmount = item.payment_credit
						accounts.creditExchangeAmount = item.payment_credit_in_exchange
						console.log('item.payment_credit > 0')
						break
					case false:
						accounts.debitAccount = item.payment_credit_account
						accounts.debitAmount = item.payment_credit * -1
						accounts.debitExchangeAmount = item.payment_credit_in_exchange * -1
						accounts.creditAccount = item.payment_debit_account
						accounts.creditAmount = item.payment_credit * -1
						accounts.creditExchangeAmount = item.payment_credit_in_exchange * -1
						break
				}
				return {
					paymentId: item.payment_id,
					invoiceNumber: item.invoice_number,
					ourCompany: item.our_company,
					customerId: item.pyments_customers_rel.customer_id,
					customerTitle: item.pyments_customers_rel.customer_title,
					paymentDate: item.payment_date,
					paymentCreditInExchange: item.payment_credit_in_exchange,
					paymentCurrency: item.payment_currency,
					paymentCreditInExchangeFormatted: floatedCurrencyAddedNumber(
						item.payment_credit_in_exchange,
						item.payment_currency,
					),
					paymentExchangeRate: item.payment_exchange_rate,
					paymentExchangeRateFormatted: Number.parseFloat(
						item.payment_exchange_rate,
					).toFixed(4),
					paymentCredit: item.payment_credit,
					paymentCreditFormatted: intToFloatWithoutCurrency(
						item.payment_credit,
					),
					paymentExchangeDifference: item.payment_exchange_difference,
					paymentExchangeDifferenceFormatted: floatedCurrencyAddedNumber(
						item.payment_exchange_difference,
						'kr.',
					),
					paymentCreditAccount: accounts.creditAccount,
					creditAmount: accounts.creditAmount,
					creditExchangeAmount: accounts.creditExchangeAmount,
					paymentDebitAccount: accounts.debitAccount,
					debitAmount: accounts.debitAmount,
					debitExchangeAmount: accounts.debitExchangeAmount,
					reportType: getReportType(radio.value),
					paymentType: getPaymentAbbreviation(item.payment_type),
				}
			})
			console.log('getTableValues inside map', tableValues.value)
		}
		const getReportType = (payload) => {
			switch (payload) {
				case '%collect%':
					return 'collectedPaymentReport'
				case '%pay%':
					return 'payoutReport '
				case '%set off%':
					return 'setOffReport'
				case 'set off credit invoice':
					return 'setOffReport'
				default:
					return 'setOffReport'
			}
		}
		const listArr = computed(() => tableValues.value)
		const columns = [
			{
				field: 'invoiceNumber',
				label: 'Fakturanr',
				width: '100',
			},
			{
				field: 'customerId',
				label: 'Kundnr',
				width: '180',
			},
			{
				field: 'customerTitle',
				label: 'Kundnamn',
				width: '180',
			},
			{
				field: 'paymentDate',
				label: 'Betaldat',
				width: '120',
			},
			{
				field: 'paymentCreditInExchangeFormatted',
				label: 'Bellop',
				width: '120',
			},
			{
				field: 'paymentExchangeRateFormatted',
				label: 'Betalkurs',
				width: '120',
			},
			{
				field: 'paymentCreditFormatted',
				label: 'Bellop kr',
				width: '120',
			},
			{
				field: 'paymentCreditAccount',
				label: 'Kredit Konto',
				width: '120',
			},
			{
				field: 'paymentDebitAccount',
				label: 'Debet Konto',
				width: '120',
			},
			{
				field: 'paymentExchangeDifferenceFormatted',
				label: 'Kursdifferenser',
				width: '120',
			},
		]
		const { floatedCurrencyAddedNumber, intToFloatWithoutCurrency } =
			useReportNumber()
		const vm = getCurrentInstance()
		const NAMESPACE = '96e1b132-5765-5e15-b9a8-8a6cec456dda'

		function getUUID() {
			let TIMEPARAMETER = (
				Date.now() + Math.floor(Math.random() * 1000000000000000000).toString()
			).toString()
			return vm.$uuid.v5(TIMEPARAMETER, NAMESPACE)
		}
		const bookKeepHeadersInsertInput = ref([])
		const paymentId = ref(0)
		// ------
		const { mutate: setKeptTrue } = useMutation(
			SetPaymentBookKeptTrueMutation,
			() => ({
				variables: {
					payment_id: paymentId.value,
				},
			}),
		)
		// ------
		const pushHeader = (payment) => {
			console.log('--- inside push header paymentDate', payment.paymentDate)
			const bookKeepInsertLinesInput = pushLines(payment)
			const bookKeepId = getUUID()
			console.log('payment.paymentId', payment.paymentId)
			paymentId.value = payment.paymentId
			setKeptTrue()
			const headerObject = {
				book_keep_date: payment.paymentDate,
				book_keep_headers_book_keep_lines_rel: {
					data: bookKeepInsertLinesInput,
				},
				book_keep_id: bookKeepId,
				customer_or_supplier_id: payment.customerId,
				customer_or_supplier_title: payment.customerTitle,
				document_number: payment.invoiceNumber,
				exchange_rate: payment.paymentExchangeRate,
				exchange_unit: payment.paymentCurrency,
				our_company: payment.ourCompany,
				report_type: payment.reportType,
				transaction_type: payment.paymentType,
				payment_id: payment.paymentId,
				payment_credit_amount: payment.paymentCredit,
				payment_credit_exchange_amount: payment.paymentCreditInExchange,
			}
			bookKeepHeadersInsertInput.value.push(headerObject)
		}
		const pushLines = (payment) => {
			const lines = ref([])
			const debitObject = ref({})
			const creditObject = ref({})
			const isExchangeDifference = ref(false)
			const pushLinesVariables = (data) => {
				return new Promise((resolve, reject) => {
					if (data !== null || undefined) {
						console.log('>>>>>>>> data', data)
						debitObject.value = {
							account_id_name: data.paymentDebitAccount,
							credit: 0,
							credit_in_exchange: 0,
							debit: data.debitAmount,
							debit_in_exchange: data.debitExchangeAmount,
						}
						creditObject.value = {
							account_id_name: data.paymentCreditAccount,
							credit: data.creditAmount,
							credit_in_exchange: data.creditExchangeAmount,
							debit: 0,
							debit_in_exchange: 0,
						}
						isExchangeDifference.value = data.paymentExchangeDifference !== 0
						// paymentId.value = data.payment_id
						resolve(isExchangeDifference.value)
					} else {
						const reason = new Error('Payment is empty or undefined')
						reject(reason)
					}
				})
			}
			pushLinesVariables(payment).then((payload) => {
				lines.value.push(debitObject.value, creditObject.value)
				if (payload) {
					const debitExchangeDifferenceObject = ref({})
					const creditExchangeDifferenceObject = ref({})
					console.log('>>>> typeof', typeof payment.payment_exchange_difference)
					const difference = computed(() => {
						return parseInt(
							parseFloat(payment.payment_exchange_difference) * 100,
						)
					})
					// ** -------------------------------------------------------------------------------
					const exchangeDifference = reactive({
						debitExchangeAccount: '',
						debitExchangeAmount: 0,
						creditExchangeAccount: '',
						creditExchangeAmount: 0,
					})
					switch (difference.value) {
						case difference.value > 0:
							exchangeDifference.debitExchangeAccount =
								'3969 Orealiserade valutakursvinster'
							exchangeDifference.debitExchangeAmount = difference.value
							exchangeDifference.creditExchangeAccount =
								'1513 Orealiserade kursdifferenser på kundfordringar'
							exchangeDifference.creditExchangeAmount = difference.value
							break
						case difference.value < 0:
							exchangeDifference.debitExchangeAccount =
								'1513 Orealiserade kursdifferenser på kundfordringar'
							exchangeDifference.debitExchangeAmount = difference.value * -1
							exchangeDifference.creditExchangeAccount =
								'7969 Orealiserade valutakursförluster'
							exchangeDifference.creditExchangeAmount = difference.value * -1
							break
						default:
							exchangeDifference.debitExchangeAccount = ''
							exchangeDifference.debitExchangeAmount = 0
							exchangeDifference.creditExchangeAccount = ''
							exchangeDifference.creditExchangeAmount = 0
					}
					// ** ------------------------------------------------------------------------------
					debitExchangeDifferenceObject.value = {
						account_id_name: exchangeDifference.debitExchangeAccount,
						credit: 0,
						credit_in_exchange: 0,
						debit: exchangeDifference.debitExchangeAmount,
						debit_in_exchange: exchangeDifference.debitExchangeAmount,
					}
					creditExchangeDifferenceObject.value = {
						account_id_name: exchangeDifference.creditExchangeAccount,
						credit: exchangeDifference.creditExchangeAmount,
						credit_in_exchange: exchangeDifference.creditExchangeAmount,
						debit: 0,
						debit_in_exchange: 0,
					}
					// ** -------------------------------------------------------------------------------
					lines.value.push(
						debitExchangeDifferenceObject.value,
						creditExchangeDifferenceObject.value,
					)
				}
			})
			return lines.value
		}
		const pushMutationVariables = (paymentsArray) => {
			return new Promise((resolve, reject) => {
				if (paymentsArray !== null || undefined) {
					bookKeepHeadersInsertInput.value = []
					paymentsArray.forEach((payment) => {
						pushHeader(payment)
					})
					resolve('OK')
				} else {
					const reason = new Error('Payments Array is empty or undefined')
					reject(reason)
				}
			})
		}
		const { mutate: bookKeepPayment, onDone } = useMutation(
			BookKeepPaymentMutation,
			() => ({
				variables: {
					header: bookKeepHeadersInsertInput.value,
				},
			}),
		)
		const bookKeepPayments = () => {
			pushMutationVariables(tableValues.value)
				.then(() => {
					bookKeepPayment()
				})
				.then(() => Router.push({ name: 'CreateCollectedPaymentsReport' }))
		}
		onDone(() => {
			console.log('done')
		})
		return {
			radio,
			showWeekNumber,
			firstDayOfWeek,
			locale,
			getData,
			listArr,
			columns,
			bookKeepPayments,
			// setValues,
			// showSetCreditExchange,
			reportData,
		}
	},
}
</script>

<style scoped>
.smallFonts {
	font-size: 0.8em;
}
.form-btn {
	margin-left: 20px;
}
.tale-style {
	margin-top: 30px;
}
</style>
